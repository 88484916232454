import { useEffect } from "react";
import { PERMISSIONS } from "../PermissionsConstant";

const MultiselectDropdown = ({ projects, filterProject, setFilterProject }) => {
  const permissions = localStorage.getItem("permissions");

  // Set default selected values
  useEffect(() => {
    const defaultSelected = projects
      ?.filter(
        (project) =>
          project._id !== "66f68fda5cf392010e45f9f0" &&
          project._id !== "66f68fb55cf392010e45f9db"
      )
      ?.map((project) => project._id);

    if (
      permissions.includes(
        PERMISSIONS.REPORTS_UTILISATION.VIEW_ALL_UTILISATION_REPORT
      )
    ) {
      setFilterProject(["all", ...defaultSelected]);
    } else {
      setFilterProject(defaultSelected);
    }
  }, [projects, permissions, setFilterProject]);

  const handleProjectChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setFilterProject(selectedValues);
  };

  return (
    <div className="bg-white shadow-md p-6 rounded-lg">
      <label className="block text-lg font-semibold text-gray-700 mb-3">
        Select Project <sup className="text-red-600">*</sup>
      </label>
      <select
        multiple
        value={filterProject}
        onChange={handleProjectChange}
        className="block w-full border border-gray-300 rounded-lg shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500 text-gray-700 h-40 overflow-y-auto"
      >
        {permissions.includes(
          PERMISSIONS.REPORTS_UTILISATION.VIEW_ALL_UTILISATION_REPORT
        ) && (
          <option value="all" className="text-gray-900">
            All Projects
          </option>
        )}
        {projects
          ?.filter(
            (project) =>
              project._id !== "66f68fda5cf392010e45f9f0" &&
              project._id !== "66f68fb55cf392010e45f9db"
          )
          ?.map((project) => (
            <option
              key={project._id}
              value={project._id}
              className="text-gray-900"
            >
              {project.projectName}
            </option>
          ))}
      </select>
    </div>
  );
};

export default MultiselectDropdown;
