import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext/AuthContext';
import Login from './components/LoginSectiom/Login';
import HomeComponent from './components/HomeComponent';
import AdminRouter from './components/router/AdminRouter';
import UserRouter from './components/router/UserRouter';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
const App = () => {
  useEffect(() => {
    const lastLoginTimestamp = localStorage.getItem('loginTimeStamp');
    const currentTimestamp = Date.now();

    // Set the initial login timestamp if it doesn't exist
    if (!lastLoginTimestamp) {
      localStorage.setItem('loginTimeStamp', currentTimestamp);
      // return; // Exit if this is the first login
    }

    // Convert the timestamp to a number for calculations
    const lastLoginTime = Number(lastLoginTimestamp);

    // Check if the user is still logged in or needs to be redirected
    if (currentTimestamp - lastLoginTime === 0) {
      // This condition may not be useful as it checks for immediate time equality
      localStorage.clear();
      window.location.href = '/login';
    }

    // Check if more than 20 minutes have passed since the last login
    if ((currentTimestamp - lastLoginTime) / 1000 / 60 / 60 / 24 > 7) {
      localStorage.clear(); // Clear localStorage on timeout
      // window.location.href = '/login'; // Redirect to login if inactive for more than a week
    }
    const params = new URLSearchParams(window.location.search);
    const redirectLink = params.get('redirect');
    console.log('Redirect Link', redirectLink);
    if (redirectLink) {
      if (localStorage.getItem('uid')) {
        window.location.href = redirectLink;
      } else {
        localStorage.setItem('redirectAfterLogin', redirectLink);
      }
    }
  }, []);

  const roles = localStorage.getItem('token');

  if (!localStorage.getItem('uid')) {
    localStorage.clear();
  }
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ForgotPassword />} />
          <Route path="*" element={<AuthBasedRouter />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Determine which routes to render based on user role

const AuthBasedRouter = () => {
  const [isAdminRoute, setIsAdminRoute] = useState(null);

  useEffect(() => {
    // Check local storage for roles
    const roles = localStorage.getItem('roles');

    const appRoles = localStorage.getItem('appRoles');

    //  if (appRoles?.includes("Project Manager")) {
    //       setIsProjectManager(true)
    //     }else if (appRoles?.includes("Admin")) {
    //       setIsAdmin(true)
    //     } else {
    //       setIsOtherUser(true)
    //     }

    if (roles) {
      const parsedRoles = JSON.parse(roles);
      setIsAdminRoute(parsedRoles.includes('ADMIN'));
    } else {
      setIsAdminRoute(false);
    }
  }, []);

  // If still determining auth state, show a loading state
  if (isAdminRoute === null) {
    return <div>Loading...</div>;
  }

  // Redirect to login if no token is present
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Redirect based on role
  return isAdminRoute ? <AdminRouter /> : <UserRouter />;
};

// export const AuthBasedRouter = () => {

//   if (isAdmin === null) {
//     // Show a loading state or placeholder while checking roles
//     return <div>Loading...</div>;
//   }

//   if (isAdmin) {
//     // If admin, show AdminRouter
//     return <AdminRouter />;
//   } else {
//     // If not admin, show UserRouter
//     return <UserRouter />;
//   }
// };

export default App;
