import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";
import { useNavigate } from "react-router-dom";
import { IoBookmarksOutline, IoCloseCircle } from "react-icons/io5";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiMessage2Fill } from "react-icons/ri";
import { TbMessage2Cog } from "react-icons/tb";
const WeeklyHoursTable = ({
  data,
  filteredProjectId,
  filteredUsers,
  startDate,
  endDate,
  refetch,
}) => {
  const navigate = useNavigate();
  const [comments, setComments] = useState(
    data.reduce(
      (acc, item) => ({ ...acc, [item._id]: item.comment[0] || "" }),
      {}
    )
  );

  console.log("Filtered", filteredUsers);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [utilisationDetails, showUtilisationDetails] = useState(false);
  const [userId, setUserId] = useState(false);
  const [projectSumData, setProjectSumData] = useState([]);
  const [timesheetCommentData, setTimesheetCommentData] = useState([]);
  const [activeTab, setActiveTab] = useState("manager");

  function convertDateFormat(dateStr) {
    // Split the input date string by "/"
    const [day, month, year] = dateStr.split("/");

    // Return the date in YYYY-MM-DD format
    return `${year}-${month}-${day}`;
  }

  const fetchTimesheetDetails = async (userId) => {
    setProjectSumData([]);
    if (userId) {
      const response = await axios.get(`
      ${BASE_URL}/api/timesheet/getTimeSheets?userId=${userId}&startDate=${convertDateFormat(
        startDate
      )}&endDate=${convertDateFormat(endDate)}`);
      showUtilisationDetails(true);
      console.log("Response Sum By Project", sumTimeByProject(response?.data));
      setProjectSumData(sumTimeByProject(response?.data));
    }
  };
  const fetchTimesheetCommentData = async (
    userId,
    comment,
    weekNumber,
    year,
    startDate,
    endDate
  ) => {
    // /api/user/reports/getComments/2024-09-02/2024-12-08/66f684dd2dd99f822044e53c

    try {
      if (userId) {
        const response = await axios.get(`
      ${BASE_URL}/api/user/getUserComments/${userId}/${year}/${weekNumber}/${startDate}/${endDate}`);

        if (response.status === 200) {
          showCommentBox(response?.data?.weeklyComments);
          console.log("Response ", response.data);
          setTimesheetCommentData(response?.data?.data);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function sumTimeByProject(entries) {
    const projectTimeMap = {};

    entries?.forEach((entry) => {
      const projectId = entry.field_proj._id;
      const projectName = entry.field_proj.projectName;
      const timeSpent = parseFloat(entry.field_time_spent) || 0;

      // Check if the project already exists in the map
      if (projectTimeMap[projectId]) {
        projectTimeMap[projectId].totalTimeSpent += timeSpent;
      } else {
        // Initialize the project entry
        projectTimeMap[projectId] = {
          projectId,
          projectName,
          totalTimeSpent: timeSpent,
        };
      }
    });

    // Convert the projectTimeMap object to an array of objects
    const result = Object.values(projectTimeMap);

    return result;
  }

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [comment, showCommentBox] = useState(null);

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;
    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    console.log("Filtered Project IDs:", filteredProjectId);
    console.log("Filtered User IDs:", filteredUsers);
    console.log("Original Data:", sortedData);

    // Return all data if "all" is selected in both filters
    if (filteredProjectId.includes("all") && filteredUsers.includes("all")) {
      return sortedData;
    }

    let filtered = sortedData;

    // Filter by project ID if "all" is not selected
    if (!filteredProjectId.includes("all")) {
      filtered = filtered.filter((item) =>
        item.projects.some((project) =>
          filteredProjectId.includes(String(project.projectId))
        )
      );
    }

    // Filter by user ID if "all" is not selected
    if (!filteredUsers.includes("all")) {
      filtered = filtered.filter((item) =>
        filteredUsers.includes(String(item.userId))
      );
    }

    console.log("Filtered Data:", filtered);
    return filtered;
  }, [sortedData, filteredProjectId, filteredUsers]);

  const handleCommentChange = (id, value) => {
    setComments({ ...comments, [id]: value });
  };

  // const handleCommentSubmit = async (id) => {
  //   const comment = comments[id];
  //   try {
  //     await axios.post(`${BASE_URL}/api/user/report/addComment/${id}`, {
  //       comment,
  //     });
  //   } catch (error) {
  //     console.error("Error updating comment:", error);
  //     alert("Failed to update comment.");
  //   }
  // };

  const handleCommentSubmit = async ({ userId: id, weekNumber, year }) => {
    console.log("Data is", id);
    const comment = comments[id];
    if (!comment) {
      alert("Comment cannot be empty.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/api/user/report/addComment`,
        {
          comment: comment,
          userId: id,
          weekNumber,
          year,
        }
      );

      if (response.status === 200) {
        // Assuming the API returns the updated user data with comments
        alert("Comment added successfully!");
        refetch();
        // Update the comments array for the user locally
        // setComments((prev) => {
        //   const updatedComments = [...(prev[id] || []), comment];
        //   return { ...prev, [id]: updatedComments };
        // });
      } else {
        alert("Failed to update comment. Please try again.");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      alert("Failed to update comment.");
    } finally {
    }
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  return (
    <div className="container mx-auto relative">
      <div className="container mx-auto relative">
        <div className="overflow-x-auto max-h-[600px]">
          {/* Limit height for scrolling */}
          <div className="flex px-5 font-extrabold text-xs gap-10 bg-indigo-200  rounded-lg p-4 shadow-2xl w-[25rem]  my-4">
            <p className="font-extrabold">
              Available Hours (Avl) = {filteredData?.[0]?.allocatedHours}
              Hrs
            </p>
            <p className="font-extrabold">
              Holidays (Hol) = {filteredData?.[0]?.holiday}Hrs
            </p>
          </div>
          <table className="min-w-full bg-white border border-gray-500 rounded-lg shadow-sm col-span-1 border-collapse">
            <thead className="bg-gray-100 text-gray-700 text-xs font-semibold sticky top-0 z-10 ">
              <tr className="text-[10px] font-extrabold h-[3.5rem] ">
                {[
                  { key: "name", label: "Name" },
                  { key: "employementStatus", label: "Status" },
                  // { key: "allocatedHours", label: "Alloc" },
                  { key: "loggedHours", label: "Log" },
                  // { key: "holiday", label: "Holiday" },
                  { key: "leave", label: "Lv" },
                  { key: "learning", label: "Lrn" },
                  { key: "flaggedHours", label: "Flg" },
                  // {
                  //   key: "allocatedMHoliday",
                  //   label: (
                  //     <code className="underline bg-yellow-200 px-2 py-1 rounded-lg">
                  //       All-Hol
                  //     </code>
                  //   ),
                  // },
                  {
                    key: "loggedMFlagged",
                    label: (
                      <div className="flex flex-col justify-center items-center">
                        <p>Adj Log*</p>
                        <code className="underline bg-red-200 px-2 py-1 rounded-lg">
                          Log-(Flg+Lv+Lrn+Hol)
                        </code>
                      </div>
                    ),
                  },
                  {
                    key: "difference",
                    label: (
                      <div className="flex flex-col justify-center items-center">
                        <p className="">Difference</p>
                        <code className="underline bg-red-200 px-2 py-1 rounded-lg">
                          Adj Log<sup>*</sup>-Avl
                        </code>
                      </div>
                    ),
                  },
                ].map((column) => (
                  <th
                    key={column.key}
                    onClick={() => handleSort(column.key)}
                    className="px-2 py-2 text-left cursor-pointer border border-gray-300"
                  >
                    {column.label} {getSortIndicator(column.key)}
                  </th>
                ))}
                <th className="px-2 py-2 text-left border border-gray-300">
                  Comment
                </th>
                <th className="px-2 py-2 text-center border border-gray-300">
                  Actions
                </th>
                <th className="px-2 py-2 text-center border border-gray-300">
                  Details
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredData.map(
                (item) =>
                  item.isActive && (
                    <tr
                      key={item._id}
                      className="hover:bg-gray-50 border border-gray-300"
                    >
                      <td
                        className="px-2 py-2 text-sm cursor-pointer text-blue-500 underline border border-gray-300"
                        onClick={() => navigate()}
                      >
                        <a
                          target="_blank"
                          href={`/admin/timesheet?startDate=${startDate}&endDate=${endDate}&projectId=${filteredProjectId}&userId=${item.userId}`}
                        >
                          {item.name}
                        </a>
                      </td>
                      <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.employementStatus?.substring(0, 3).toUpperCase()}
                      </td>
                      {/* <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.allocatedHours || 0}
                      </td> */}
                      <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.loggedHours || 0}
                      </td>
                      {/* <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.holiday || 0}
                      </td> */}
                      <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.leave || 0}
                      </td>
                      <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.learning || 0}
                      </td>
                      <td className="px-2 py-2 text-sm border border-gray-300">
                        {item.flaggedHours}
                      </td>
                      {/* <td className="px-2 py-2 text-sm border border-gray-300">
                        {item?.allocatedHours - item?.holiday || 0}
                      </td> */}
                      <td className="px-2 py-2 text-sm border border-gray-300">
                        {(
                          item?.loggedHours -
                            (item?.flaggedHours +
                              item?.leave +
                              item?.learning +
                              item?.holiday) || 0
                        ).toFixed(1)}
                      </td>
                      <td
                        className={`px-2 py-2 text-sm font-semibold border border-gray-300" ${
                          (
                            item?.loggedHours -
                              (item?.flaggedHours +
                                item?.leave +
                                item?.learning +
                                item?.holiday) -
                              item?.allocatedHours || 0
                          ).toFixed(1) > 0
                            ? "text-green-600"
                            : (
                                item?.loggedHours -
                                  (item?.flaggedHours +
                                    item?.leave +
                                    item?.learning +
                                    item?.holiday) -
                                  item?.allocatedHours || 0
                              ).toFixed(1) < 0
                            ? "text-red-600"
                            : "text-yellow-500"
                        }`}
                      >
                        {(
                          item?.loggedHours -
                            (item?.flaggedHours +
                              item?.leave +
                              item?.learning +
                              item?.holiday) -
                            item?.allocatedHours || 0
                        ).toFixed(1)}
                      </td>
                      <td className="px-2 py-2 flex items-center gap-2   border-gray-300 ">
                        <input
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              handleCommentSubmit(item.userId);
                            }
                          }}
                          type="text"
                          className="w-full px-2 py-1 text-sm   rounded-md focus:outline-none focus:ring focus:ring-blue-200 border"
                          value={comments[item.userId]}
                          onChange={(e) =>
                            handleCommentChange(item.userId, e.target.value)
                          }
                          placeholder="Add comment"
                        />
                        <button
                          type="button"
                          className="background-transparent text-gray-500 hover:text-gray-700 hover:bg-slate-200 p-2 rounded-full"
                          onClick={() => {
                            fetchTimesheetCommentData(
                              item.userId,
                              item.comment,
                              item.weekNumber,
                              item.year,
                              item.startDate,
                              item.endDate
                            );
                          }}
                        >
                          <TbMessage2Cog className="text-2xl" />
                        </button>
                      </td>
                      <td className="px-1 py-2 text-center border border-gray-300">
                        <button
                          onClick={() => {
                            handleCommentSubmit(item);
                            setUserId(item.userId);
                          }}
                          className="px-2 py-1 text-xs font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                        >
                          Save
                        </button>
                      </td>
                      <td className="px-1 py-2 text-center border border-gray-300">
                        <button
                          className="background-transparent text-gray-500 hover:text-gray-700 hover:bg-slate-200 p-2 rounded-full"
                          onClick={() => {
                            fetchTimesheetDetails(item.userId);
                          }}
                        >
                          <HiDotsHorizontal />
                        </button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {comment && (
        <div className="bg-gray-800/80 shadow-lg rounded mx-auto my-auto w-full h-screen fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center z-50">
          <div className="w-[45rem] min-h-[25rem] max-h-[40rem] rounded-lg bg-white p-6 flex flex-col">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
              <p className="font-bold text-blue-500 text-xl underline">
                Comments
              </p>
              <button onClick={() => showCommentBox(null)}>
                <IoCloseCircle className="text-3xl text-gray-500 hover:text-red-600" />
              </button>
            </div>

            {/* Tabs */}
            <div className="flex border-b mb-4">
              <button
                className={`flex-1 py-2 text-center font-semibold ${
                  activeTab === "manager"
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("manager")}
              >
                Project Manager / Lead Comments
              </button>
              <button
                className={`flex-1 py-2 text-center font-semibold ${
                  activeTab === "timesheet"
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("timesheet")}
              >
                Flagged Comments
              </button>
            </div>

            {/* Tab Content */}
            <div className="flex-1 overflow-y-auto">
              {activeTab === "manager" && (
                <div className="space-y-4">
                  {comment?.length > 0 ? (
                    <ul className="space-y-2">
                      {comment?.map((com, index) =>
                        index > 0 ? (
                          <li
                            key={index}
                            className="p-4 bg-blue-50 border-l-4 border-blue-400 shadow-sm rounded-lg hover:shadow-md transition-shadow duration-200"
                          >
                            <div className="flex items-start">
                              <span className="font-semibold text-blue-600 mr-3">
                                {index}.
                              </span>
                              <p className="text-gray-700 leading-relaxed font-semibold">
                                {com?.comment}
                              </p>
                            </div>
                          </li>
                        ) : null
                      )}
                    </ul>
                  ) : (
                    <div className="text-center py-12 bg-gray-50 rounded-lg shadow-inner">
                      <p className="text-gray-500 font-medium italic">
                        No comments available.
                      </p>
                    </div>
                  )}
                </div>
              )}

              {activeTab === "timesheet" && (
                <div className="space-y-4">
                  {timesheetCommentData?.length > 0 ? (
                    timesheetCommentData?.map((timesheet) => (
                      <div
                        key={timesheet._id}
                        className="bg-gray-50 shadow rounded-lg p-4"
                      >
                        <div className="border-b pb-2 mb-2">
                          <h3 className="text-gray-800 font-semibold">
                            Timesheet ID: {timesheet._id}
                          </h3>
                          <p className="text-gray-600 text-sm">
                            <span className="font-medium">User:</span>{" "}
                            {timesheet.uid}
                          </p>
                          {timesheet?.field_entrydate && (
                            <p className="text-gray-600 text-sm">
                              <span className="font-medium">Date:</span>{" "}
                              {new Date(
                                timesheet.field_entrydate
                              ).toDateString()}
                            </p>
                          )}
                        </div>
                        {timesheet?.flagComment?.length > 0 ? (
                          timesheet?.flagComment?.map((comment) => (
                            <div
                              key={comment._id}
                              className="p-3 bg-blue-50 border rounded-lg mb-2"
                            >
                              <p className="text-blue-700 font-medium">
                                <span className="font-bold">Query:</span>{" "}
                                {comment.query}
                              </p>
                              <p className="text-sm text-gray-500">
                                {new Date(comment.createdAt).toLocaleString()}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="text-gray-500 italic">
                            No comments available.
                          </p>
                        )}
                      </div>
                    ))
                  ) : (
                    <div>
                      <div className="text-center py-12 bg-gray-50 rounded-lg shadow-inner">
                        <p className="text-gray-500 font-medium italic">
                          No comments available.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {utilisationDetails && (
        <div className="bg-gray-800/80 shadow-lg rounded  mx-auto my-auto w-full h-screen   fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center z-50">
          <div className="w-[45rem]  min-h-[25rem] max-h-[40rem] rounded-lg bg-white p-10 flex flex-col">
            <div className="flex justify-between w-full ">
              <p className="font-bold text-blue-500 text-xl underline font-montserrat">
                Project Summary
              </p>
              <button
                onClick={() => {
                  showUtilisationDetails(!utilisationDetails);
                }}
              >
                <IoCloseCircle className="text-3xl" />
              </button>
            </div>
            <div className="overflow-x-auto mt-3 ">
              <table className="w-full table-fixed border-collapse border border-gray-300">
                <thead className="bg-gray-800 text-white sticky top-0">
                  <tr>
                    <th className="w-1/3 p-3 border border-gray-300 text-left">
                      Sr No.
                    </th>
                    <th className="w-1/3 p-3 border border-gray-300 text-left">
                      Project Name
                    </th>

                    <th className="w-1/3 p-3 border border-gray-300 text-left">
                      Total Time Spent
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projectSumData?.map((item, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 even:bg-gray-50"
                    >
                      <td className="p-3 border border-gray-300">
                        {index + 1}
                      </td>
                      <td className="p-3 border border-gray-300">
                        {item.projectName}
                      </td>

                      <td className="p-3 border border-gray-300">
                        {item.totalTimeSpent} hrs
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeeklyHoursTable;
