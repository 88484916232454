import React, { useState } from "react";
import axios from "axios"; // For making HTTP requests
import { IoClose } from "react-icons/io5";
import { BASE_URL } from "../../Constants";

// Enum for Roles (should match the values in your backend)
export const roles = [
  "Project Manager",
  "Developer",
  "HR",
  "TA",
  "QA",
  "DevOps",
  "Admin",
  "Operations",
  "Accounts",
  "Intern",
  "Freelancer/ Contractor",
  "Marketing",
  "Director/Leadership",
  "Reporting Manager",
];

// Enum for Employment Status (should match the values in your backend)
export const employmentStatuses = [
  "Fulltime",
  "Intern",
  "Consultant",
  "Freelancer",
];

const CreateUserForm = ({ handleClose, refetch }) => {
  // State for form inputs
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    roles: [], // Default role
    projects: [],
    employeeId: "",
    employmentStatus: "",
    isActive: true,
    wup_username: "",
    wup_uid: "",
  });

  // State for errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Email regex validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle form validation
  const validateForm = () => {
    let errors = {};

    if (!formData.fname) errors.fname = "First name is required";
    if (!formData.lname) errors.lname = "Last name is required";
    if (!formData.wup_username) errors.lname = "Whatsup username is required";
    if (!formData.wup_uid) errors.lname = "Whatsup uid is required";
    if (!validateEmail(formData.email))
      errors.email = "Please enter a valid email address";
    if (formData.roles.length === 0)
      errors.roles = "At least one role is required";
    if (!formData.employeeId) errors.employeeId = "Employee ID is required";
    if (!formData.employmentStatus)
      errors.employmentStatus = "Employment status is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle adding a new role
  const handleAddRole = (e) => {
    const newRole = e.target.value;
    if (newRole && !formData.roles.includes(newRole)) {
      setFormData((prev) => ({ ...prev, roles: [...prev.roles, newRole] }));
    }
  };

  // Handle removing a role
  const handleRemoveRole = (roleToRemove) => {
    setFormData((prev) => ({
      ...prev,
      roles: prev.roles.filter((role) => role !== roleToRemove),
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop if form is invalid

    try {
      // Send the data to your backend API
      const response = await axios.post(
        `${BASE_URL}/api/user/createUser`,
        formData,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      ); // Adjust your endpoint URL
      console.log("User created successfully:", response.data);
      if (response.status === 200 || response.status === 201) {
        handleClose(false);
        refetch();
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    <div className="mx-auto p-2 relative text-[#333333] ">
      <button
        type="button"
        className="absolute right-5"
        onClick={() => handleClose(false)}
      >
        <IoClose className="text-2xl text-[#333333]" />
      </button>
      <h1 className="text-2xl font-semibold mb-6 text-[#333333]">
        Add New User
      </h1>

      <form onSubmit={handleSubmit} className="space-y-2 ">
        {/* First Name */}
        <div className="flex gap-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 font-montserrat">
              First Name:<sup className="text-red-500">*</sup>
            </label>
            <input
              type="text"
              name="fname"
              value={formData.fname}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
            {errors.fname && (
              <p className="text-red-500 text-sm">{errors.fname}</p>
            )}
          </div>

          {/* Last Name */}
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 font-montserrat">
              Last Name:<sup className="text-red-500">*</sup>
            </label>
            <input
              type="text"
              name="lname"
              value={formData.lname}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
            {errors.lname && (
              <p className="text-red-500 text-sm">{errors.lname}</p>
            )}
          </div>
        </div>

        <div className="flex gap-4">
          {/* Email */}
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 font-montserrat">
              Email:<sup className="text-red-500">*</sup>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
          </div>
          {/* Employee ID */}
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 font-montserrat">
              Employee ID:<sup className="text-red-500">*</sup>
            </label>
            <input
              type="text"
              name="employeeId"
              value={formData.employeeId}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
            {errors.employeeId && (
              <p className="text-red-500 text-sm">{errors.employeeId}</p>
            )}
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 font-montserrat">
              WUP UID:<sup className="text-red-500">*</sup>
            </label>
            <input
              type="text"
              name="wup_uid"
              value={formData.wup_uid}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
            {errors.wup_uid && (
              <p className="text-red-500 text-sm">{errors.wup_uid}</p>
            )}
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 font-montserrat">
              WUP Username:<sup className="text-red-500">*</sup>
            </label>
            <input
              type="text"
              name="wup_username"
              value={formData.wup_username}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
            {errors.wup_username && (
              <p className="text-red-500 text-sm">{errors.wup_username}</p>
            )}
          </div>
        </div>

        {/* Roles Selection */}
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 font-montserrat">
            Roles:<sup className="text-red-500">*</sup>
          </label>
          <div className="flex flex-wrap  ">
            {formData.roles.map((role) => (
              <div
                key={role}
                className="flex items-center bg-gray-200 rounded-full px-4 py-1"
              >
                <span>{role}</span>
                <IoClose
                  onClick={() => handleRemoveRole(role)}
                  className="ml-2 text-xl cursor-pointer text-red-500"
                />
              </div>
            ))}
          </div>

          <select
            onChange={handleAddRole}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
          {errors.roles && (
            <p className="text-red-500 text-sm">{errors.roles}</p>
          )}
        </div>

        {/* Employment Status */}
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 font-montserrat">
            Employment Status:<sup className="text-red-500">*</sup>
          </label>
          <select
            name="employmentStatus"
            value={formData.employmentStatus}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          >
            <option value="">Select Status</option>
            {employmentStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          {errors.employmentStatus && (
            <p className="text-red-500 text-sm">{errors.employmentStatus}</p>
          )}
        </div>

        {/* Is Active Checkbox */}
        <div className="flex items-center py-4">
          <input
            type="checkbox"
            name="isActive"
            checked={formData.isActive}
            onChange={() =>
              setFormData({ ...formData, isActive: !formData.isActive })
            }
            className="h-4  w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          />
          <label className="ml-2 block text-sm text-gray-900">Is Active</label>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#077FC0]/90 text-white font-semibold py-2 px-4 rounded-md shadow hover:bg-[#077FC0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-montserrat"
        >
          Create User
        </button>
      </form>
    </div>
  );
};

export default CreateUserForm;
